'use client';
import Error from 'next/error';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

export default function GlobalError ({ error }: { error: any }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        {/* <Error statusCode={error.statusCode} title={error.message} /> */}
        <Error statusCode={error.statusCode} title={error.message} />
      </body>
    </html>
  );
}
